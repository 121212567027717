<template>
  <div>
    <b-row v-if="width<768">
      <b-col md="12">
        <b-button v-b-modal.modal-hierachy>
          <feather-icon icon="GitPullRequestIcon" />
        </b-button>
      </b-col>
    </b-row>
    <b-card>
      <!-- FILTER START -->
      <b-row>
        <b-col md="3">
          <b-form-group :label="$t('labels.type')">
            <v-select
              :value="$store.value"
              v-model="filterSelect.type"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="typeOptions"
              label="key"
              @input="inputSelect"
              :reduce="(status) => status.value"
              placeholder="Selecciona un Status"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.datefrom')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateFrom"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
        <b-col md="3">
          <b-form-group :label="$t('labels.dateto')" rules="required">
            <flat-pickr
              v-model="filterSelect.dateTo"
              @input="inputSelect"
              class="form-control"
              :config="{
                          altInput: true,
                          altFormat: 'd-m-Y h:i K',
                          enableTime: true,
                          wrap : true,
                          plugins: [
                             new yearDropdownPlugin({
                               yearStart: 100,
                               yearEnd: 0
                            })
                           ],
                        }"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- FILTER END -->
    </b-card>
    <b-row>
      <b-col md="10">
        <b-card>
          <b-overlay :show="loadingData" variant="transparent" no-wrap />
          <b-modal
            id="modalResponse"
            title="PopUp Response"
            cancel-variant="outline-secondary"
            scrollable
          >
            <b-card-text>
              {{ rows }}
            </b-card-text>
          </b-modal>
          <!-- table -->
          <b-table
            md="9"
            :items="items"
            :fields="fields"
            class="scroll"
            responsive
          >
            <template #thead-top="data">
              <b-tr>
                <b-th colspan="1" style="border: none"></b-th>
                <b-th colspan="5" class="border border-white text-center"
                  >Ingresos</b-th
                >
                <b-th colspan="2" class="text-center">Egresos</b-th>
                <b-th colspan="2" class="text-center">Tickets</b-th>
                <b-th colspan="2" class="text-center">Saldo</b-th>
              </b-tr>
            </template>
            <template #cell(label)="data">
              <svg
                v-if="data.item.label == 'WEB'"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-laptop"
                viewBox="0 0 16 16"
              >
                <path
                  d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z"
                />
              </svg>
              <span v-else-if="data.item.label == 'DIRECT'">SIMPLE</span>
              <span v-else-if="data.item.label == 'PARLAY'">COMBINADO</span>
              <svg
                v-else-if="data.item.label == 'MOBILE'"
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                fill="currentColor"
                class="bi bi-phone-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M3 2a2 2 0 0 1 2-2h6a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V2zm6 11a1 1 0 1 0-2 0 1 1 0 0 0 2 0z"
                />
              </svg>
              <span v-else>
                {{ data.item.label }}
              </span>
            </template>
            <template #cell(countId.sum)="data">
              <span
                >{{ data.item.countId.sum | currency({ symbol: "" }) }}</span
              >
            </template>
            <template #cell(grossSales.sum)="data">
              <span
                >{{ data.item.grossSales.sum | currency({ symbol: "" }) }}</span
              >
            </template>
            <template #cell(returns.sum)="data">
              <span
                >{{ data.item.returns.sum | currency({ symbol: "" }) }}</span
              >
            </template>
            <template #cell(nullBets.sum)="data">
              <span
                >{{ data.item.nullBets.sum | currency({ symbol: "" }) }}</span
              >
            </template>
            <template #cell(netSales.sum)="data">
              <span
                >{{ data.item.netSales.sum | currency({ symbol: "" }) }}</span
              >
            </template>
            <template #cell(won.sum)="data">
              <span>{{ data.item.won.sum | currency({ symbol: "" }) }}</span>
            </template>
            <template #cell(paid.sum)="data">
              <span>{{ data.item.paid.sum | currency({ symbol: "" }) }}</span>
            </template>
            <template #cell(expired.sum)="data">
              <span
                >{{ data.item.expired.sum | currency({ symbol: "" }) }}</span
              >
            </template>
            <template #cell(inGame.sum)="data">
              <span>{{ data.item.inGame.sum | currency({ symbol: "" }) }}</span>
            </template>
            <template #cell(utilityBalance.sum)="data">
              <span
                v-if="data.item.utilityBalance.sum === 0.00"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ data.item.utilityBalance.sum | currency({ symbol: "" }) }}</span
              >
              <span
                v-else-if="data.item.utilityBalance.sum > 0.00"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
              >
                {{ data.item.utilityBalance.sum | currency({ symbol: "" }) }}</span
              >
              <span
                v-else-if="data.item.utilityBalance.sum < 0.00"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f4436a; color:#FFFFFF;"
              >
                {{ data.item.utilityBalance.sum | currency({ symbol: "" }) }}</span
              >
            </template>
            <template #cell(cashBalance.sum)="data">
              <span
                v-if="data.item.cashBalance.sum === 0.00"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #25476a; color:#FFFFFF;"
              >
                {{ data.item.cashBalance.sum | currency({ symbol: "" }) }}</span
              >
              <span
                v-else-if="data.item.cashBalance.sum > 0.00"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #8bc34a; color:#FFFFFF;"
              >
                {{ data.item.cashBalance.sum | currency({ symbol: "" }) }}</span
              >
              <span
                v-else-if="data.item.cashBalance.sum < 0.00"
                style="width: 100%; height: 100px; text-align:center; padding:5px; margin:auto; display: inline_block; vertical-align: middle; border-radius: 10px;background: #f4436a; color:#FFFFFF;"
              >
                {{ data.item.cashBalance.sum | currency({ symbol: "" }) }}</span
              >
            </template>
          </b-table>
          <b>TOTALES</b>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-start
                "
              >
                <span class="text-muted"
                  >{{ $t("labels.showing") }} {{ "-" }} {{ $t("labels.to") }}
                  {{ "-" }} {{ $t("labels.of") }} {{ "-" }}
                  {{ $t("labels.entries") }}</span
                >
              </b-col>
              <!-- Pagination -->
              <b-col
                cols="12"
                sm="6"
                class="
                  d-flex
                  align-items-center
                  justify-content-center justify-content-sm-end
                "
              >
                <b-pagination
                  v-model="serverParams.page"
                  :total-rows="totalRows"
                  :per-page="serverParams.limit"
                  first-number
                  last-number
                  class="mb-0 mt-1 mt-sm-0"
                  prev-class="prev-item"
                  next-class="next-item"
                  :simple="true"
                  :hide-goto-end-buttons="true"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </b-col>
      <b-modal
        id="modal-hierachy"
        hide-footer
        title="Listado de agentes"
        v-if="width<768"
      >
        <HierarchyUsers @selectUser="showData" ref="HerarchiComponent" />
      </b-modal>
      <b-col class="p-0" md="2" v-else>
        <b-card no-body class="p-0 py-1">
          <HierarchyUsers @selectUser="showData" />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BOverlay,
  BPagination,
  BFormSelect,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BInputGroup,
  BCollapse,
  VBToggle,
  VBModal,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import moment from "moment";
import HierarchyUsers from "@core/components/HierarchyUsers/HierarchyUsers.vue";
import axios from '@/libs/axios';
import yearDropdownPlugin from "@/helpers/year_flatpickr_plugin";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BOverlay,
    BPagination,
    BFormSelect,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BInputGroup,
    BCollapse,
    VBModal,
    BCardText,
    VBToggle,
    vSelect,
    VueGoodTable,
    flatPickr,
    ToastificationContent,
    HierarchyUsers,
  },
  directives: {
    "b-toggle": VBToggle,
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      fields: [
        { label: "Tipo de Apuesta", key: "label" },
        { label: "Cantidad de Tickets", key: "countId.sum" },
        { label: "Venta Bruta", key: "grossSales.sum" },
        { label: "Devoluciones", key: "returns.sum" },
        { label: "Nulos", key: "nullBets.sum" },
        { label: "Venta Neta", key: "netSales.sum" },
        { label: "Premios", key: "won.sum" },
        { label: "Pagado", key: "paid.sum" },
        { label: "Vencidos", key: "expired.sum" },
        { label: "En Juego", key: "inGame.sum" },
        { label: "Utilidad", key: "utilityBalance.sum" },
        { label: "Saldo en Caja", key: "cashBalance.sum" },
      ],
      loadingData: false,
      items: [],
      dataIdUser: [],
      rows: [],
      serverParams: {
        page: 1,
        limit: 20,
      },
      totalRows: 0,
      typeOptions: [
        { key: "General", value: "ALL" },
        { key: "Por usuarios", value: "USERS" },
        { key: "Por plataforma", value: "PLATFORM" }
      ],
      filterSelect: {
        ticketId: "",
        status: "",
        type: "",
        dateFrom: moment()
          .subtract(7, "days")
          .startOf("day")
          .format("YYYY-MM-DD HH:mm:ss"),
        dateTo: moment().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
      },
      width: 0,
    };
  },
  methods: {
    yearDropdownPlugin,
    showData({ id }) {
      this.dataIdUser = id;
      let date = { dateFrom: new Date(this.filterSelect.dateFrom), dateTo: new Date(this.filterSelect.dateTo) }
      try {
        this.loadingData = true;
        const VUE_APP_URL = process.env.VUE_APP_URL;
        const params = {
          id: this.dataIdUser,
          whitelabel: this.$store.state.whitelabelCurrencyNabvar.whitelabel._id,
          currency: this.$store.state.whitelabelCurrencyNabvar.currency,
          dateFrom: date.dateFrom.toISOString(),
          dateAt: date.dateTo.toISOString(),
          type: this.filterSelect.type == '' ? 'ALL' : this.filterSelect.type,
        };

        axios
          .post(`${VUE_APP_URL}/sportsbook/bankingAgency`, params)
          .then((r) => {
            let data = r.data.data;
            if (this.filterSelect.type == 'ALL' || this.filterSelect.type == '') {
              this.items = data.BET;

            }
            if (this.filterSelect.type == 'USERS') {
              this.items = data.USERS;

            }
            if (this.filterSelect.type == 'PLATFORM') {
              this.items = data.PLATFORM;

            }
          })
          .catch((error) => console.log(error.response));
      } catch (error) {
        console.log(error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Error fetching ticket",
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      } finally {
        this.loadingData = false;
      }
    },
    inputSelect() {
      let id = this.dataIdUser;

      this.showData({ id });
    },
  },
  created() {
        this.width = window.innerWidth;
        window.addEventListener('resize', () => {
            this.width = window.innerWidth;
        });
    },
  computed: {},
  // add filters
  filters: {
    moment: function (date) {
      return moment(date).format("MM/DD/YYYY HH:mm:ss");
    },
  },
};
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.scroll::-webkit-scrollbar {
  -webkit-appearance: none;
}

.scroll::-webkit-scrollbar-button:increment,
.scroll::-webkit-scrollbar-button {
  display: none;
}

.scroll::-webkit-scrollbar:horizontal {
  height: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #797979;
  border-radius: 20px;
}

.scroll::-webkit-scrollbar-track {
  border-radius: 10px;
}

@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
